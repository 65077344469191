:root {
  --text-color: #565759;
  --background-color: #fff;
  --extra-small-device-height: 68%;
  --small-device-height: 66%;
}

.log-in-page {
  background: var(--background-color);

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .grid-col_left-container {
    margin-left: auto;
    padding-left: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 42px;
      font-weight: 400;
      line-height: 49px;
      color: $black-charcoal;
    }

    p {
      margin-top: 20px;
      margin-bottom: 20px;
      color: var(--text-color);
    }

    ul li {
      color: var(--text-color);
      line-height: 24px;
      font-size: 1rem;
    }

    .grid-col_left-btn {
      display: inline-block;
      margin-top: 15px;
      padding: 12px 24px;
      background: $palette-pink-500;
      border-radius: 4px;
      color: white;
      font-weight: 500;

      &:hover {
        background: $palette-pink-700;
      }
    }

    .grid-col_left-container-text {
      margin-top: 20%;
    }

    .grid-col_left-container-info {
      padding-bottom: 70px;
    }
  }

  .grid-col_right-container {
    padding-left: 60px;
    margin-top: 20%;
    .bs-institution-login {
      margin-top: 1.875rem;
    }
    .grid-col_right-container-athens {
      margin-bottom: 20%;
    }
    .altLogin-grouping-nhs p {
      margin-bottom: 0;
    }
    .altLogin-grouping-nhs h2,
    .institution-title {
      color: $black-charcoal;
      font-size: 24px;
      line-height: 32px;
    }
    p, ul {
      color: $black-charcoal;
    }
    .bs-institution-login {
      padding: 12px 24px;
      border-radius: 4px;

      &:hover {
        background: $palette-blue-700;
      }
    }
  }

  .grid-col_left-container-info p {
    color: var(--text-color);
  }

  #panel-nhs {
    margin-top: 0;
  }

  main {
    position: relative;

    &:after {
      content: "";
      background: $palette-grey-a400;
      width: 50%;
      height: 100%;
      display: block;
      top: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
  }
}

#mainNav {
  float: none !important;
}
.log-in-info-mobile {
  display: none;
}
/* Media Queries */


@media (min-width: 576px) and (max-width: 768px) {
  .log-in-page {
    .grid-col_left-container-info {
      display:none;
    }
    .grid-col_left-container .grid-col_left-container-info {
      padding-bottom: 0;
    }
    .grid {
      grid-template-columns: 1fr;
    }

    main:after {
      content: unset;
    }

    .grid-col_right-container {
      margin-top: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .log-in-page {
    .grid-col_left-container-info {
      display:none;
    }
    .grid-col_left-container .grid-col_left-container-info {
      padding-bottom: 0;
    }
    .grid {
      grid-template-columns: 1fr;
    }

    main:after {
      content: unset;
    }

    .grid-col_right-container {
      margin-top: 0;
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 991.5px) {
  .grid-col_left-container-info {
    display:none;
  }
  .log-in-page {

    .grid-col_left {
      margin-bottom: 50px;
    }
    main:after {
      content: "";
      background: #f2f2f2;
      width: 100%;
      height: 60%;
      display: block;
      top: unset;
      bottom: 0;
      position: absolute;
      z-index: -1;
    }
    .grid-col_right-container .grid-col_right-container-athens {
      margin-bottom: 4%;
    }
    .grid-col_left-container-info {
      display:block;
    }
  }

  .region-code-us main:after,
  .region-code-br main:after,
  .region-code-cn main:after{
    height: 50%;
  }

  .log-in-page .grid-col_left-container .grid-col_left-container-text{
    margin-top: 5%;
  }
}
@media screen and (max-width: 947px) and (min-width: 564px) {
  .grid-col_left-container-info {
    display:none;
  }
  .log-in-page .grid-col_left-container .grid-col_left-container-info{
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  .log-in-page {
    .grid-col_right-container .grid-col_right-container-athens {
      margin-bottom: 4%;
    }
    .country-us,
    .country-pt,
    .country-cn,
    .country-br{
      margin-top: 50px;
    }
    .log-in-info-mobile {
      display: block;
      padding: 15px 10px;
    }

    .grid-col_left-container-info {
      display:none;
    }
    .grid-col_left-container .grid-col_left-container-info {
      padding-bottom: 0;
    }
    .grid-col_left-container {
      padding: 0;

      .grid-col_left-container-text {
        margin-top: 0;
      }
    }

    .grid {
      grid-template-columns: 1fr;
    }

    main:after {
      content: unset;
    }
    .grid-col_left{
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 50px;
    }
    .grid-col_right {
      background: #f2f2f2;
      padding-left: 15px;
      padding-right: 15px;
    }
    .grid-col_right-container {
      margin-top: 0;
      padding-left: 0;
    }
  }
}